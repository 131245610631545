import { gql } from "@apollo/client";

export const SeriesFragment = gql`
  fragment Series on Series {
    id
    name
    signOnStatus
    organisation {
      id
      name
      shortName
      contactEmail
    }
    rankingsDivisions {
      ...Division
    }
    membershipDivisions {
      ...Division
    }
    options {
      federatedMembership
      rollingMembership {
        unit
        length
      }
      currency
      defaultDivisionPrice
      divisions {
        id
        price
      }
      extraDivisionPrice
      familyTotal
      extras {
        uuid
        name
        price
        options
      }
    }
    registrationOptions {
      waitlisted
      notes
      termsAndConditionsLink
      termsOwner
      divisionLimit
      divisions {
        id
        maxAge
        maxBy
        minAge
        minBy
      }
    }
    captureFields {
      uuid
      label
      type
      level
      required
      config {
        options
        docusealTemplateSlug
        validation {
          url
          parameters {
            name
            path
          }
        }
        requireIf {
          operator
          conditions {
            property
            comparator
            value
          }
        }
      }
    }
  }
`;

export const DELETE_SERIES = gql`
  mutation deleteSeries($id: ID!) {
    deleteSeries(input: {id: $id}) {
      series {
        id
      }
    }
  }
`;

export const GET_SERIES_RANKINGS = gql`
  query getSeriesRankings($id: ID!, $divisionId: ID!, $filter: String) {
    series(id: $id) {
      id
      rankings(divisionId: $divisionId, filter: $filter) {
        athlete {
          id
          name
          image
        }
        displayProperty
        place
        points
      }
    }
  }
`;

export const GET_SERIES_ATHLETE_RANKING_RESULTS = gql`
  query getSeriesResults($id: ID!, $divisionId: ID!, $athleteId: ID!, $filter: String) {
    series(id: $id) {
      id
      athleteRankingResults(divisionId: $divisionId, athleteId: $athleteId, filter: $filter) {
        resultsToCount,
        eligibleResults,
        eventsToCount,
        results {
          id
          place
          points
          dropped
          eventDivision {
            id
            event {
              id
              date
              name
            }
          }
        }
      }
    }
  }
`;
